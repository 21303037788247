import * as React from "react"
import Layout from "../../components/layout"
import Header from "../../components/header";

import WorkSubPage from "../../components/workSubpage";

const id = 'isb';

const WorkPage = () => {
    return (
        <main>
            <Header/>
            <Layout>
                <WorkSubPage id={id}/>
            </Layout>
        </main>
    )
}

export default WorkPage
